var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"bg-page",attrs:{"fluid":"","fill-height":"","justify-center":"","align-center":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6","lg":"4"}},[_c('transition',{attrs:{"name":"fade"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],attrs:{"elevation":"12"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('password_reset')))])],1),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
var validate = ref.validate;
return [_c('v-card-text',{staticClass:"px-6"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"email",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"email","error-messages":errors,"success":valid,"disabled":_vm.loading,"prepend-icon":"mdi-email","label":_vm.$t('email'),"clearable":"","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"px-4 pb-6"},[_c('v-btn',{attrs:{"text":"","disabled":_vm.loading,"to":{ name: 'login'},"color":"grey darken-2"}},[_vm._v(_vm._s(_vm.$vuetify.breakpoint.xsOnly ? _vm.$t('back') : _vm.$t('remember_your_password'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":_vm.loading || invalid || !validated}},[_vm._v(_vm._s(_vm.$t('reset_password'))+" ")])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }